import { FileUploader } from "react-drag-drop-files";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ArrowUpward, Widgets } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import TemporaryDrawer from "Drawers/AdminDrawer";
import theme from "Helpers/theme";
import conectadoIcon from "img/ConectadoIcon.png";
import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import * as tus from "tus-js-client";
import { useFilePicker } from "use-file-picker";
import { useSnackbar } from "notistack";
import {useDropzone} from 'react-dropzone'
import Dropzone from 'react-dropzone'
import { ButtonBase } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';

const fileTypes = ["MP4", "MOV"];

export default function TusUpload(props) {
  const [progress, setProgress] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [count, setCount] = React.useState(0);
  const percentageRef = React.useRef(-1);

  const [file, setFile] = React.useState(null);


  const uploadFile = (upFile) => {
    // if (upFile.name.includes("'")){
    //   enqueueSnackbar(`File names cannot include apostrophes ( ' )`, { variant: "error", preventDuplicate: true,  autoHideDuration: null});
    // }
    const upload = new tus.Upload(upFile, {
      endpoint: '/files', // Replace with your TUS PHP endpoint
      chunkSize: 5242880, // Optional: 5MB chunks
      metadata: {
          filename: upFile.name.replace("'", ""),
          filetype: upFile.type
      },
      onError: function (error) {
          console.error('Upload failed:', error);
          percentageRef.current = 4;
          setProgress(false);
          enqueueSnackbar("Upload Failed", { variant: "error", preventDuplicate: true,  autoHideDuration: null});
          setCount(-1);

      },
      onProgress: function (bytesUploaded, bytesTotal) {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log(`${bytesUploaded}/${bytesTotal} bytes uploaded`);
          percentageRef.current = percentage;
          setCount(percentage);
      },
      onSuccess: function () {
          console.log('Upload finished:', upload.url);
          enqueueSnackbar("Upload Succeeded", { variant: "success", preventDuplicate: true,  autoHideDuration: null});
          setProgress(false);
          props.onComplete(true);
          percentageRef.current = -1;
          setCount(-1);
      }
  });
  enqueueSnackbar("Upload Started", { variant: "info", preventDuplicate: true,  autoHideDuration: 5000 });
  setProgress(true);
  // Start the upload
  upload.start();
  };

  const theme = useTheme();
  const baseStyle = {
    flex: 1,
    display: 'flex',
    width: "16rem",
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    fontWeight: "1000",
    color:theme.palette.background.paper,
    cursor: "pointer",
    textTransform: "uppercase",
    fontSize: '1rem',
    outline: 'none',
    borderRadius: '40px',
    background: 'linear-gradient(45deg, #94FFE8 -50%, #26B99A 100%)',
    boxShadow: '3px 3px 15px #24415D4D',
    '&:hover': {
      boxShadow: '3px 3px 15px #24415D4D',
    }
    
  };

  const focusedStyle = {
    border: 2,
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    border: 2,
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    border: 2,
    borderColor: '#ff1744'
  };

  function StyledDropzone(props) {

    const onDrop = React.useCallback(acceptedFiles => {
      acceptedFiles.forEach((file) => {
        console.log(file);
        uploadFile(file);
      })
    }, [])
    
    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject
    } = useDropzone({onDrop, accept: {'video/webm': [".webm"], 'video/mp4': [".mp4"]}});

    
  
    const style = React.useMemo(() => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isFocused,
      isDragAccept,
      isDragReject
    ]);

    
  
    return (
      <div className="container">
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
              {!progress && <p>Upload</p>}
              {progress && <p>{count}%</p>}
              {progress && <LinearProgress variant="determinate" value={count} sx={{width:"60%"}} />}
        </div>
      </div>
    );
  }


  return (
    <div className="flex justify-center items-center mt-4 flex-col gap-4">
            <div>
              <StyledDropzone />
            </div>
    </div>
  );
}
