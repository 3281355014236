import App from 'Base/App.js';
import * as ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';
import './index.css';


if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <App />
);
