import Typography from "@mui/material/Typography";
import * as React from "react";
import APIRequest from "Helpers/ApiRequest";
import { useSnackbar } from "notistack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import Moment from "react-moment";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { Link as muiLink } from "@mui/material/Link";
import Button from "@mui/material/Button";
import Draggable from "react-draggable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { ArrowUpward } from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import TusUpload from "Helpers/TusUpload";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function Videos() {
  function createFileData(
    file,
    firstname,
    lastname,
    id,
    mime_type,
    time_added,
    time_updated,
    enabled
  ) {
    return {
      file,
      firstname,
      lastname,
      id,
      mime_type,
      time_added,
      time_updated,
      enabled
    };
  }

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [fileRows, setFileRows] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [currentTranscript, setCurrentTranscript] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function RetrieveFiles() {
    var newRows = [];
    var req = await APIRequest({
      action: "get_uploads",
      mime_type: "%mp4%,%video/mp4%,%video/mpeg%,%video/webm%",
    });
    if (req.status == true) {
      req.data.uploads.forEach((element) => {
        newRows.push(
          createFileData(
            element.file,
            element.firstname,
            element.lastname,
            element.id,
            element.mime_type,
            element.time_added,
            element.time_updated,
            element.hidden
          )
        );
      });
    } else {
      handleOpen("error", req.message);
    }
    return newRows;
  }

  const handleOpen = (severity, message) => {
    const severities = ["error", "warning", "info", "success"];
    if (!severities.includes(severity)) {
      severity = "warning";
    }
    enqueueSnackbar(message, { variant: severity, preventDuplicate: true });
  };

  async function getFileRowsAsync() {
    const row = await RetrieveFiles();
    setFileRows(row);
  }
  React.useEffect(() => {
    getFileRowsAsync();
  }, []);

  const getURL = async (url, suggestedName) => {
    await fetch(url)
      .then((r) => r.blob())
      .then((r) => saveFile(r, suggestedName));
  };

  const saveFile = async (blob, suggestedName) => {
    // Feature detection. The API needs to be supported
    // and the app not run in an iframe.
    const supportsFileSystemAccess =
      "showSaveFilePicker" in window &&
      (() => {
        try {
          return window.self === window.top;
        } catch {
          return false;
        }
      })();
    // If the File System Access API is supported…
    if (supportsFileSystemAccess) {
      try {
        // Show the file save dialog.
        const handle = await showSaveFilePicker({
          suggestedName,
        });
        // Write the blob to the file.
        const writable = await handle.createWritable();
        await writable.write(blob);
        await writable.close();
        return;
      } catch (err) {
        // Fail silently if the user has simply canceled the dialog.
        if (err.name !== "AbortError") {
          console.error(err.name, err.message);
        }
        return;
      }
    }
    // Fallback if the File System Access API is not supported…
    // Create the blob URL.
    const blobURL = URL.createObjectURL(blob);
    // Create the `<a download>` element and append it invisibly.
    const a = document.createElement("a");
    a.href = blobURL;
    a.download = suggestedName;
    a.style.display = "none";
    document.body.append(a);
    // Programmatically click the element.
    a.click();
    // Revoke the blob URL and remove the element.
    setTimeout(() => {
      URL.revokeObjectURL(blobURL);
      a.remove();
    }, 1000);
  };


  async function handleVideoToggle(id, hide) {
      var req = await APIRequest({
        action: "hide_upload",
        id: id,
        hide: hide ? 1 : 0,
      });
      if (req.status == true) {
        handleOpen("success", req.message);
        getFileRowsAsync();
      } else {
        handleOpen("error", req.message);
      }
    }



  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Transcript
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{currentTranscript}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogContentText>
            Automatically generated by ChatGPT/Whisper
          </DialogContentText>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {fileRows.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="Video Table">
            <TableHead>
              <TableRow>
                <TableCell>File</TableCell>
                <TableCell align="left">Owner</TableCell>
                <TableCell align="right">Filetype</TableCell>
                <TableCell align="right">Time Created</TableCell>
                <TableCell align="right">Enabled</TableCell>
                <TableCell align="right">Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fileRows.map((row) => (
                <TableRow key={row.file}>
                  <TableCell component="th" scope="row">
                    {row.file}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.firstname + " " + row.lastname}
                  </TableCell>
                  <TableCell align="right">{row.mime_type}</TableCell>
                  <TableCell align="right">
                    <Moment format="YYYY/MM/DD HH:mm">
                      {row.time_added * 1000}
                    </Moment>
                  </TableCell>
                  <TableCell align="right">
                    <Checkbox
                      checked={!row.enabled}
                      onChange={(event) => handleVideoToggle(row.id, !row.enabled)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      onClick={() =>
                        getURL(
                          `${process.env.PUBLIC_URL}/uploads/${row.file}`,
                          row.file
                        )
                      }
                    >
                      Download
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className="flex-initial flex-col grow-0">
        <TusUpload onComplete={getFileRowsAsync}/>
      </div>
    </React.Fragment>
  );
}
